import { delet, get, post, put } from "../../core/HTTPService.service";
import CapitalLimitsResponse from "../Tarifier/Model/Response/CapitalLimitsResponse.model";
import CarrierPricesRequest from "./Model/Request/CarrierPricesRequest.model";
import LeadProjectRequest from "./Model/Request/LeadProjectRequest.model";
import OrganicRequest from "./Model/Request/OrganicRequest.model";
import PostStackRequest, {
  BeneficiariesRequest,
  BeneficiariesTypeRequest,
} from "./Model/Request/PostStackRequest.model";
import CarrierPricesResponse, {
  PDF,
} from "./Model/Response/CarrierPricesResponse.model";
import NomenclatorResponse from "./Model/Response/NomenclatorResponse.model";
import OrganicResponse from "./Model/Response/OrganicResponse.model";
import PostStackResponse, {
  BeneficiariesResponse,
} from "./Model/Response/PostStackResponse.model";
import QuestionResponse, {
  SaveQuestionRequest,
} from "./Model/Response/QuestionResponse.model";

import PricesResponse, {
  PriceByCapitalResponse,
} from "./Model/Response/PricesResponse.model";
import LeadAskPriceRequest from "./Model/Request/LeadAskPriceRequest.model";
import {
  PreviewProjectResponse,
  UpdateProjectData,
  ViewProjectResponse,
} from "./Model/EditProjectReponse.model";
import { SimulatePremiumRequest } from "./Model/Request/SimulatePremiumRequest";
import { SimulatePremiumResponse } from "./Model/Response/SimulatePremiumResponse";
import { SQLBoundariesResponse } from "./Model/Response/SQLBoundariesResponse";
import { SQLBoundariesRequest } from "./Model/Request/SQLBoundariesRequest";
import OrganicSQLRequest from "./Model/Request/OrganicSQLRequest";
import OrganicSQLResponse from "./Model/Response/OrganicSQLResponse";
import { SQLPricingResponse } from "./Model/Response/SQLPricingResponse";
import { SQLRulesResponse } from "./Model/Response/SQLRulesResponse";
import { GuaranteeFormI } from "../../../views/dashboard/multiproduct/MultiproductData";
import { GetLeadDocumentationI } from "./Model/Request/GetLeadDocumentation.model";

export default class HttpLeadRepository {
  private readonly API_URL:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/`;
  private readonly API_URL_LEAD:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/lead`;
  private readonly API_URL_INSURANCE:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMERS_APP_URL}insurance/calculate`;

  private readonly header: { Authorization: string } | undefined;

  private readonly API_URL_EVENTS:
    | string
    | undefined = `${process.env.REACT_APP_EVENTS_API}public/front/event`;

  private readonly API_URL_PUBLIC:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/public`;

  private readonly API_URL_CHECKPOINT:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/chekpoint`;

  private readonly API_URL_PAYMENT:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/payment`;

  constructor(token?: string) {
    this.header = token ? { Authorization: `Bearer ${token}` } : undefined;
  }

  deleteBeneficiaries = async (leadId: string) => {
    try {
      await delet(
        `${this.API_URL_LEAD}/${leadId}/beneficiaries`,
        {},
        this.header
      );
    } catch (error) {
      throw error;
    }
  };

  downloadPDF = async (leadId: string, template: any): Promise<PDF> => {
    let pdf: PDF | null;
    try {
      const response: PDF = await get(
        `${this.API_URL_LEAD}/pdf/${leadId}?templates=${template}`,
        this.header
      );
      pdf = response;
    } catch (error) {
      throw error;
    }
    return pdf;
  };

  locationByCategory = async (
    category: string
  ): Promise<NomenclatorResponse> => {
    let location: NomenclatorResponse | null;
    try {
      const response: NomenclatorResponse = await get(
        `${this.API_URL_LEAD}/question/nomenclator/get/${category}`,
        this.header
      );
      location = response;
    } catch (error) {
      throw error;
    }
    return location;
  };

  getCarrierPrices = async ({
    leadId,
    params,
  }: CarrierPricesRequest): Promise<CarrierPricesResponse> => {
    let prices: CarrierPricesResponse | null;
    const BASE_URL: string = `${this.API_URL_LEAD}/${leadId}/carriers/prices`;
    const url: string = params
      ? `${BASE_URL}${params.capital}/${params.insuranceType}`
      : BASE_URL;
    try {
      const response: CarrierPricesResponse = await get(url, this.header);
      prices = response;
    } catch (error) {
      throw error;
    }
    return prices;
  };

  getLeadBoundaries = async (
    leadId: string
  ): Promise<CapitalLimitsResponse> => {
    let capitalLimits: CapitalLimitsResponse | null;
    try {
      const response: CapitalLimitsResponse = await get(
        `${this.API_URL_LEAD}/${leadId}/boundaries`,
        this.header
      );
      capitalLimits = response;
    } catch (error) {
      throw error;
    }
    return capitalLimits;
  };

  getLeadExtendedBoundaries = async (
    leadId: string
  ): Promise<CapitalLimitsResponse> => {
    let capitalLimits: CapitalLimitsResponse | null;
    try {
      const response: CapitalLimitsResponse = await get(
        `${this.API_URL_LEAD}/${leadId}/extended-boundaries`,
        this.header
      );
      capitalLimits = response;
    } catch (error) {
      throw error;
    }
    return capitalLimits;
  };

  getPostStackData = async (leadId: string): Promise<PostStackResponse> => {
    let postStackData: PostStackResponse | null;
    try {
      const response: PostStackResponse = await get(
        `${this.API_URL_LEAD}/data/${leadId}`,
        this.header
      );
      postStackData = response;
    } catch (error) {
      throw error;
    }
    return postStackData;
  };

  getPrice = async (leadId: string): Promise<PricesResponse> => {
    let price: PricesResponse | null;
    try {
      const response: PricesResponse = await get(
        `${this.API_URL_LEAD}/${leadId}/price`
      );
      price = response;
    } catch (error) {
      throw error;
    }
    return price;
  };

  getPriceByCapital = async (
    leadId: string,
    capital: number
  ): Promise<PriceByCapitalResponse> => {
    let price: PriceByCapitalResponse | null;
    try {
      const response: PriceByCapitalResponse = await get(
        `${this.API_URL_INSURANCE}/${leadId}/${capital}`
      );
      price = response;
    } catch (error) {
      throw error;
    }
    return price;
  };

  postOrganic = async (
    data: OrganicRequest,
    throwError: boolean = false
  ): Promise<OrganicResponse> => {
    let organicData: OrganicResponse | null = null;
    try {
      const response: OrganicResponse = await post(
        `${this.API_URL_LEAD}/organic`,
        data,
        {},
        throwError
      );
      organicData = response;
    } catch (e) {
      throw e;
    }

    return organicData;
  };

  postReducedOrganicEs = async (
    data: OrganicRequest,
    method?: "post" | "put",
    throwError: boolean = false
  ): Promise<{}> => {
    let organicData: OrganicResponse | null = null;
    try {
      let response: OrganicResponse;
      if (method === "put") {
        response = await put(
          `${this.API_URL}public/lead/organic/es`,
          data,
          {},
          throwError
        );
      } else {
        response = await post(
          `${this.API_URL}public/lead/organic/es`,
          data,
          {},
          throwError
        );
      }
      organicData = response;
    } catch (e) {
      throw e;
    }
    return organicData;
  };

  postReducedOrganicSQL = async (
    data: OrganicSQLRequest,
    method?: "post" | "put",
    throwError: boolean = false
  ): Promise<OrganicSQLResponse> => {
    let organicData: OrganicSQLResponse | null = null;
    try {
      let response: OrganicSQLResponse;
      if (method === "put") {
        response = await put(
          `${this.API_URL}public/lead/organic/sql-es`,
          data,
          {},
          throwError
        );
      } else {
        response = await post(
          `${this.API_URL}public/lead/organic/sql-es`,
          data,
          {},
          throwError
        );
      }
      organicData = response;
    } catch (e) {
      throw e;
    }
    return organicData;
  };

  getSQLBoundaries = async ({
    birthdate,
  }: SQLBoundariesRequest): Promise<any> => {
    let prices: SQLBoundariesResponse | null = null;
    try {
      const response = await get(
        `${this.API_URL}public/boundaries/base-trait/${birthdate}`,
        this.header
      );
      prices = response;
    } catch (error) {
      throw error;
    }
    return prices;
  };

  getSQLPricing = async (leadId: string): Promise<any> => {
    let prices: SQLPricingResponse | null = null;
    try {
      const response = await get(
        `${this.API_URL}chekpoint/pricing-capitals/${leadId}`,
        this.header
      );
      prices = response;
    } catch (error) {
      throw error;
    }
    return prices;
  };

  updateAdditionalBoundaries = async (
    leadId: string,
    guarantee: {
      trait: keyof GuaranteeFormI;
      capital: number;
    }
  ): Promise<null> => {
    let boundaries: null = null;
    try {
      const response = await put(
        `${this.API_URL}organic/update-additional-boundaries/${leadId}`,
        guarantee,
        this.header
      );
      boundaries = response;
    } catch (error) {
      throw error;
    }
    return boundaries;
  };

  updateAdditionalBoundariesFromCheckpoint = async (
    leadId: string,
    guarantee: {
      trait: keyof GuaranteeFormI;
      capital: number;
    }
  ): Promise<null> => {
    let boundaries: null = null;
    try {
      const response = await put(
        `${this.API_URL}update-checkpoint-additional-boundaries/${leadId}`,
        guarantee,
        this.header
      );
      boundaries = response;
    } catch (error) {
      throw error;
    }
    return boundaries;
  };

  updateComplementBoundaries = async (
    leadId: string,
    guarantee: {
      trait: keyof GuaranteeFormI;
      capital: number;
    }
  ): Promise<null> => {
    let boundaries: null = null;
    try {
      const response = await put(
        `${this.API_URL}organic/update-complement-boundaries/${leadId}`,
        guarantee,
        this.header
      );
      boundaries = response;
    } catch (error) {
      throw error;
    }
    return boundaries;
  };

  getSQLRules = async (leadId: string): Promise<any> => {
    let prices: SQLRulesResponse | null = null;
    try {
      const response = await get(
        `${this.API_URL}boundaries/capital-rules/${leadId}`,
        this.header
      );
      prices = response;
    } catch (error) {
      throw error;
    }
    return prices;
  };

  postStackData = async (
    leadId: string,
    data: PostStackRequest
  ): Promise<PostStackResponse> => {
    let result: PostStackResponse | null = null;
    try {
      const response: PostStackResponse = await post(
        `${this.API_URL_LEAD}/data/${leadId}`,
        data,
        this.header
      );
      result = response;
    } catch (e) {
      throw e;
    }

    return result;
  };

  postBeneficiariesType = async (
    data: BeneficiariesTypeRequest
  ): Promise<BeneficiariesResponse> => {
    let result: BeneficiariesResponse | null = null;
    try {
      const response: BeneficiariesResponse = await post(
        `${this.API_URL_LEAD}/beneficiaries/types`,
        data,
        this.header
      );
      result = response;
    } catch (e) {
      throw e;
    }

    return result;
  };

  postBeneficiaries = async (
    data: BeneficiariesRequest
  ): Promise<BeneficiariesRequest> => {
    let result: BeneficiariesRequest | null = null;
    try {
      const response: BeneficiariesRequest = await post(
        `${this.API_URL_LEAD}/beneficiaries`,
        data,
        this.header
      );
      result = response;
    } catch (e) {
      throw e;
    }

    return result;
  };

  leadAskPrice = async ({
    leadId,
    capital,
    insuranceType,
  }: LeadAskPriceRequest): Promise<any> => {
    let prices: PricesResponse | null = null;
    try {
      const response = await get(
        `${this.API_URL_LEAD}/${leadId}/price/${capital}/${insuranceType}`,
        this.header
      );
      prices = response;
    } catch (error) {
      throw error;
    }
    return prices;
  };

  getSimulatePremium = async ({
    leadId,
    capital,
    coverage,
  }: SimulatePremiumRequest): Promise<any> => {
    let prices: SimulatePremiumResponse | null = null;
    try {
      const response = await get(
        `${this.API_URL}simulate-premium/${leadId}/${capital}/${coverage}`,
        this.header
      );
      prices = response;
    } catch (error) {
      throw error;
    }
    return prices;
  };

  questionsByLeadId = async (leadId: string): Promise<any> => {
    let questions: QuestionResponse | null = null;
    try {
      const response: QuestionResponse = await get(
        `${this.API_URL_LEAD}/question/${leadId}`,
        this.header
      );
      questions = response;
    } catch (error) {
      throw error;
    }
    return questions;
  };

  saveQuestionByLead = async (data: SaveQuestionRequest): Promise<{}> => {
    let result: PostStackResponse | null = null;
    try {
      const response: PostStackResponse = await post(
        `${this.API_URL_LEAD}/question`,
        data,
        this.header
      );
      result = response;
    } catch (error) {
      throw error;
    }
    return result;
  };

  recoverProject = async ({
    leadId,
    data,
  }: LeadProjectRequest): Promise<PostStackResponse> => {
    let result: PostStackResponse | null = null;
    try {
      const response: PostStackResponse = await post(
        `${this.API_URL_LEAD}/purchase/data/${leadId}`,
        data,
        this.header
      );
      result = response;
    } catch (error) {
      throw error;
    }
    return result;
  };

  checkMinimumAnnualPremium = async ({
    leadId,
  }: CarrierPricesRequest): Promise<CarrierPricesResponse> => {
    let prices: CarrierPricesResponse | null;
    const BASE_URL: string = `${this.API_URL_LEAD}/${leadId}/carriers/check-minimum-annual-premium`;
    try {
      const response: CarrierPricesResponse = await get(BASE_URL, this.header);
      prices = response;
    } catch (error) {
      throw error;
    }
    return prices;
  };

  saveTracking = async ({ data }: any) => {
    try {
      const response = await post(
        `${this.API_URL}lead/track/v2`,
        data,
        this.header
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  saveTrackingPublic = async ({ data }: any) => {
    try {
      const response = await post(
        `${process.env.REACT_APP_CUSTOMERS_APP_URL}lead/track/v2`,
        data,
        this.header
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  getTracking = async (leadId: string) => {
    try {
      const response = await get(`${this.API_URL}tracking/get/${leadId}`);
      return response;
    } catch (error) {
      throw error;
    }
  };

  saveEvent = async ({ data }: any) => {
    const isLocalhost = window.location.hostname === "localhost";
    if (isLocalhost) return;
    try {
      const response = await post(`${this.API_URL_EVENTS}`, data);

      return response;
    } catch (error) {
      throw error;
    }
  };

  saveTerzoReferente = async (data: any) => {
    const response = await post(
      `${this.API_URL_LEAD}/contact-person/add`,
      data,
      this.header
    );

    return response;
  };

  getViewProject = async (leadId: string): Promise<ViewProjectResponse> => {
    const response = await get(
      `${this.API_URL}v3/list/item/view-project/${leadId}`,
      this.header
    );
    return response;
  };

  getPreviewProject = async (
    leadId: string
  ): Promise<PreviewProjectResponse> => {
    const response = await get(
      `${this.API_URL}v3/projects/${leadId}`,
      this.header
    );
    return response;
  };

  putLeadUpdates = async (
    leadId: string,
    data: UpdateProjectData
  ): Promise<ViewProjectResponse> => {
    const response = await put(
      `${this.API_URL}lead/update/${leadId}`,
      data,
      this.header
    );
    return response;
  };

  putLeadUpdatesSQL = async (
    leadId: string,
    data: UpdateProjectData
  ): Promise<ViewProjectResponse> => {
    const response = await put(
      `${this.API_URL}lead/update/personal/${leadId}`,
      data,
      this.header
    );
    return response;
  };

  getValidationActuarialAge = async (date: string) => {
    const response = await get(`${this.API_URL_PUBLIC}/age/validation/${date}`);
    return response;
  };

  getOtp = async (leadId: string) => {
    const response = await get(
      `${this.API_URL_LEAD}/generateOtp/${leadId}`,
      this.header
    );
    return response;
  };

  getPricingCapitals = async (leadId: string) => {
    const response = await get(
      `${this.API_URL_CHECKPOINT}/pricing-capitals/${leadId}`,
      this.header
    );
    return response;
  };

  getAvailableDocuments = async (leadId: string): Promise<any> => {
    const response = await get(
      `${this.API_URL}documents/availables/${leadId}`,
      this.header
    );
    return response;
  };

  getDocument = async (template: string, leadId: string): Promise<any> => {
    const response = await get(
      `${this.API_URL}documents/generate/${template}/${leadId}`,
      this.header
    );
    return { ...response, template };
  };

  getPaymentPricingCapitals = async (leadId: string) => {
    const response = await get(
      `${this.API_URL_PAYMENT}/pricing-capitals/${leadId}`,
      this.header
    );
    return response;
  };

  getLeadDocumentation = async (
    leadId: string
  ): Promise<GetLeadDocumentationI | null> => {
    let documentation: GetLeadDocumentationI | null = null;
    try {
      const response = await get(
        `${this.API_URL_LEAD}/documentation/${leadId}`,
        this.header
      );
      documentation = response;
    } catch (error) {
      throw error;
    }
    return documentation;
  };

  postLeadDocumentationUploadImage = async (
    leadId: string,
    data: {
      leadId: string;
      content: string;
      type: string;
    }
  ): Promise<any> => {
    let documentation: any = null;
    try {
      const response = await post(
        `${this.API_URL_LEAD}/documentation/upload/image/${leadId}`,
        data,
        this.header
      );
      documentation = response;
    } catch (error) {
      throw error;
    }
    return documentation;
  };

  postLeadDocumentationDocumentationType = async (
    leadId: string,
    documentationType: string
  ): Promise<any> => {
    let documentation: any = null;
    try {
      const response = await post(
        `${this.API_URL_LEAD}/documentation/create/${leadId}/${documentationType}`,
        { leadId, documentationType },
        this.header
      );
      documentation = response;
    } catch (error) {
      throw error;
    }
    return documentation;
  };

  putLeadDocumentationDocumentationType = async (
    leadId: string,
    documentType: string
  ): Promise<any> => {
    let documentation: any = null;
    try {
      const response = await put(
        `${this.API_URL_LEAD}/documentation/update/${leadId}/${documentType}`,
        { leadId, documentType },
        this.header
      );
      documentation = response;
    } catch (error) {
      throw error;
    }
    return documentation;
  };

  deleteLeadDocumentationDeleteImage = async (
    leadId: string,
    imageType: string
  ): Promise<any> => {
    let documentation: any = null;
    try {
      const response = await delet(
        `${this.API_URL_LEAD}/documentation/delete/image/${leadId}/${imageType}`,
        { leadId, imageType },
        this.header
      );
      documentation = response;
    } catch (error) {
      throw error;
    }
    return documentation;
  };

  skipBiometry = async (leadId: string) => {
    let result: any | null;
    try {
      const response: string = await put(
        `${this.API_URL_LEAD}/update-to-legal/${leadId}`,
        {},
        this.header
      );
      result = response;
    } catch (e) {
      throw e;
    }
    return result;
  };

  postLeadDocumentationSave = async (leadId: string): Promise<any> => {
    let documentation: any = null;
    try {
      const response = await post(
        `${this.API_URL_LEAD}/documentation/save/${leadId}`,
        { leadId },
        this.header
      );
      documentation = response;
    } catch (error) {
      throw error;
    }
    return documentation;
  };

  deleteLeads = async (brokerId: string, leads: string[]): Promise<any> => {
    let response: any = null;
    try {
      response = await delet(
        `${this.API_URL_LEAD}/delete/${brokerId}`,
        leads,
        this.header
      );
    } catch (error) {
      throw error;
    }
    return response;
  };
}
