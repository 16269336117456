// Vendors
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// Api
import HttpBrokerRepository from "../../../../api/request/Broker/broker.service";
import { handleLogin } from "../../../../utils/fetchBroker";
import { ErrorsLogin } from "../../constants/login-otp-errors.constants";
import { I18nContext } from "../../../../contexts/i18n.context";
// Handlers

const LoginOtpSubmitViewController = (
  handleBroker,
  handleBrokerId,
  handleToken,
  setStatus
) => {
  const {
    state: { translate },
  } = useContext(I18nContext);

  const [errorOtp, setErrorOtp] = useState(true);
  const [errorMessageOtp, setErrorMessageOtp] = useState("");
  const [value, setValue] = useState(null);
  const navigate = useNavigate();
  const brokerRepository = new HttpBrokerRepository();
  const controlErrors = (e) => {
    setErrorMessageOtp(ErrorsLogin[e.message]);
    setErrorOtp(true);
  };

  const handleClickSubmitOtp = () =>
    handleLogin({
      brokerRepository,
      controlErrors,
      navigate,
      otp: true,
      setBroker: handleBroker,
      setBrokerId: handleBrokerId,
      setStatus,
      setToken: handleToken,
      value,
    });

  useEffect(() => {}, [value]);
  return {
    errorMessageOtp,
    errorOtp,
    handleClickSubmitOtp,
    setErrorMessageOtp,
    setErrorOtp,
    setValue,
    translate,
    value,
  };
};

export default LoginOtpSubmitViewController;
