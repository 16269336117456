import * as React from "react";
import { useNavigate } from "react-router";
import HttpCouponsRepository from "../../../../api/request/Coupons/Coupons.service";
import HttpLeadRepository from "../../../../api/request/Lead/lead.service";
import {
  PromotionCheckpoint,
  PricesCheckpoint,
  CapitalsCheckpoint,
  CheckpointResponse,
} from "../../../../api/request/Lead/Model/Response/CarrierPricesResponse.model";
import { ContextGetlife } from "../../../../contexts/ContextGetlife";
import { I18nContext } from "../../../../contexts/i18n.context";
import { ModalDataI } from "../../../projects/EditProjects.types";
import apiLeadSendDocument from "../../../../api/request/apiLeadSendDocument";

export type ValidateCouponT = "validate" | "invalidate" | "error";

export interface CouponInfoI {
  conditions?: string;
  exceptions?: string;
  error?: string;
  code?: string;
}

export interface CouponFetchI {
  code: string;
}

export type NewDownloadableFiles =
  | [
      {
        template: string;
        url: string;
      }
    ]
  | [];

const CheckPointSQLController = () => {
  const { leadId, setLoading, token, setShowNotification } =
    React.useContext(ContextGetlife);

  const {
    state: { translate },
  } = React.useContext(I18nContext);

  const previousModal = React.useRef();

  const navigate = useNavigate();
  const [showModalCoupon, setShowModalCoupon] = React.useState<boolean>(false);
  const [validateCoupon, setValidateCoupon] = React.useState<
    ValidateCouponT | undefined
  >();
  const [couponInfo, setCouponInfo] = React.useState<CouponInfoI>();
  const [downloadableFiles, setDownloadableFiles] =
    React.useState<NewDownloadableFiles>([]);

  // NEW CODE

  const [prices, setPrices] = React.useState<PricesCheckpoint>();
  const [capitals, setCapitals] = React.useState<CapitalsCheckpoint>();
  const [promotion, setPromotion] = React.useState<PromotionCheckpoint>();
  const [downloadableOpen, setDownloadableOpen] =
    React.useState<boolean>(false);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [modalData, setModalData] = React.useState<ModalDataI | {}>({});
  const [promoLoading, setPromoLoading] = React.useState(false);

  const leadRepository = new HttpLeadRepository(token);
  const couponsRepository = new HttpCouponsRepository(leadId, token);

  const handlePreviousModal = () => {
    // @ts-ignore
    previousModal.current?.handleShowPortal();
  };

  const fetchCarrierPrices = async () => {
    setLoading(true);
    try {
      const response: CheckpointResponse =
        await leadRepository.getPricingCapitals(leadId);
      const data = await leadRepository.getViewProject(leadId);
      setCapitals(response.capitals);
      setPromotion(response.promotion);
      setPrices(response.prices);

      setModalData({
        ...data.personalData,
        ...data.product,
        leadId: leadId,
      });

      setLoading(false);
    } catch (error) {}
    setLoading(false);
  };

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      await fetchCarrierPrices();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadId]);

  React.useEffect(() => {
    (async () => {
      if (validateCoupon) {
        setLoading(true);
        await fetchCarrierPrices();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateCoupon]);

  React.useEffect(() => {
    const getDownloadableFiles = async () => {
      const _downloadableFiles: NewDownloadableFiles =
        await leadRepository.getAvailableDocuments(leadId);
      setDownloadableFiles(_downloadableFiles);
    };
    getDownloadableFiles();
  }, [leadId]);

  const contract = async () => {
    navigate(`/poststack`);
  };

  const fetchPromo = async (code: string) => {
    try {
      setPromoLoading(true);
      const response = await couponsRepository.applyCoupon({
        promotionalCode: code,
      });
      setValidateCoupon("validate");
      setCouponInfo({
        conditions: response.promo_conditions,
        exceptions: response.promo_exceptions,
        code: response.promo,
      });
      setPromoLoading(false);
    } catch (error) {
      setValidateCoupon("invalidate");
      setPromoLoading(false);
    }
  };

  const handleRemoveCoupon = async (code: string) => {
    try {
      setPromoLoading(true);
      await couponsRepository.removeCoupon({
        promotionalCode: code,
      });
      setValidateCoupon("error");
      setCouponInfo(undefined);
      setShowNotification({
        message: translate("checkpoint.coupon.remove.correct"),
        status: true,
        type: "success",
        autoHideDuration: 5000,
      });
      setPromoLoading(false);
    } catch (error) {
      setPromoLoading(false);
    }
  };

  const handleClickIcon = async () => {
    if (promotion?.promotionalCode) {
      await handleRemoveCoupon(promotion.promotionalCode);
    } else {
      setShowModalCoupon(true);
    }
  };

  const sendEmailDocument = async (
    email: string,
    text: string,
    template: string
  ) => {
    const type = "general-a-exclusions";
    const resource = null;
    try {
      await apiLeadSendDocument(
        token,
        leadId,
        type,
        text,
        email,
        resource,
        template.includes(",") ? template.split(",") : [template]
      );
      setDownloadableOpen(false);
      setShowNotification({
        message: translate("downloadableFiles.modal.send.success"),
        type: "success",
        status: true,
      });
    } catch (e) {
      setDownloadableOpen(false);
      setShowNotification({
        message: translate("downloadableFiles.modal.send.error"),
        type: "error",
        status: true,
      });
    }
  };

  return {
    capitals,
    promotion,
    prices,
    downloadableOpen,
    setDownloadableOpen,
    contract,
    fetchPromo,
    handleClickIcon,
    setShowModalCoupon,
    showModalCoupon,
    translate,
    validateCoupon,
    downloadableFiles,
    showModal,
    setShowModal,
    modalData,
    handlePreviousModal,
    previousModal,
    sendEmailDocument,
    promoLoading,
  };
};

export default CheckPointSQLController;
