import "./EditProjects.scss";
import {
  ButtonDS,
  GlobalIcon,
  InputDS,
  ModalDS,
  SelectDS,
  TabsDS,
  Toggle,
} from "get-life-storybook-ts";
import { getValidateEmail, typeInsurance } from "../../utils/staticData";
import { EditProjectsI } from "./EditProjects.types";
import EditProjectsController from "./controllers/EditProject.controller";
import DownloadableFilesModalDS from "../components/DownloadableFiles/DownloadableFilesModalDS";
import { onEnterBlur } from "../../utils/onEnterBlur";
import CouponSelectorModal from "../components/CouponSelectorModal/CouponSelectorModal";

const EditProjects: React.FC<EditProjectsI> = ({
  modalData,
  recoverProject,
  setShowModal,
  showModal,
  token,
  broker,
  fetchProjects,
}): JSX.Element => {
  const {
    translate,
    values,
    updateValues,
    fetchUpdatePrices,
    traits,
    DOMAIN,
    prices,
    isActiveRelatedEmail,
    setIsActiveRelatedEmail,
    saveData,
    isLoading,
    saveDataAndRecoverProject,
    downloadableModal,
    setDownloadableModal,
    setCouponModal,
    couponModal,
    downloadablePdfs,
    formatPriceWithoutCurrency,
    error,
    isSaved,
    closeModalCallback,
    isSeriousIllnesses,
    sendEmailDocument,
    appliedCoupon,
    promoLoading,
    validateCoupon,
    fetchPromo,
    handleRemoveCoupon
  } = EditProjectsController({
    modalData,
    recoverProject,
    setShowModal,
    fetchProjects,
    showModal,
    token,
  });

  return (
    <>
      <ModalDS
        open={showModal ?? false}
        onClose={closeModalCallback}
        icon="PencilIcon"
        title={translate("edit.project.title")}
        content={
          isSaved ? (
            <div className="flex flex-col gap-[16px]">
              <div className="bg-[#ECECFE] rounded-full p-[8px] w-fit h-fit">
                <GlobalIcon
                  iconName="CheckedIcon"
                  color={"var(--primary-color"}
                  size="XS"
                />
              </div>
              <p className="BodyM text-[#424242]">
                {translate("edit.project.saved")}
              </p>
            </div>
          ) : (
            <TabsDS
              tabs={[
                {
                  label: translate("edit.project.product"),
                  icon: "CashIcon",
                  panel: (<>
                    <div className="pt-[40px] editProjects">
                      <div className="editProjects-column">
                        <InputDS
                          type="number"
                          label={translate("edit.project.capital")}
                          name="capital"
                          value={values.capital}
                          onChange={(e) => {
                            let value: number | null = parseInt(e.target.value);
                            if (isNaN(value)) value = null;
                            updateValues("capital", value);
                          }}
                          onBlur={() => {
                            let value = values.capital;
                            if (
                              value > modalData?.capitalLimit?.maxCapitalLimit
                            ) {
                              value = modalData?.capitalLimit?.maxCapitalLimit;
                            }
                            if (
                              value < modalData?.capitalLimit?.minCapitalLimit
                            ) {
                              value = modalData?.capitalLimit?.minCapitalLimit;
                            }
                            updateValues("capital", value);
                            fetchUpdatePrices(value, values.coverage);
                          }}
                          icon="CurrencyEuroIcon"
                          onKeyDown={(e) => {
                            if (e.key === "," || e.key === ".")
                              e.preventDefault();
                          }}
                          helper={
                            prices.hasTelesubscription &&
                              prices.telesubscriptionReason === "MAX_CAPITAL"
                              ? translate("edit.projects.maxCapitalHelper")
                              : modalData?.capitalLimit?.minCapitalLimit &&
                                modalData?.capitalLimit?.maxCapitalLimit
                                ? `${translate(
                                  "edit.projects.minimum"
                                )} ${new Intl.NumberFormat("de-DE", {
                                  style: "currency",
                                  currency: "EUR",
                                  maximumFractionDigits: 0,
                                }).format(
                                  modalData?.capitalLimit?.minCapitalLimit
                                )}, ${translate(
                                  "edit.projects.maximum"
                                )} ${new Intl.NumberFormat("de-DE", {
                                  style: "currency",
                                  currency: "EUR",
                                  maximumFractionDigits: 0,
                                }).format(
                                  modalData?.capitalLimit?.maxCapitalLimit
                                )}`
                                : undefined
                          }
                          step={5000}
                          min={modalData?.capitalLimit?.minCapitalLimit}
                          max={modalData?.capitalLimit?.maxCapitalLimit}
                          onKeyDownCapture={onEnterBlur}
                        />
                        {DOMAIN === "es" && !isSeriousIllnesses ? (
                          <div>
                            <p className="BodyL newDashboard-fieldLabel">
                              {translate("edit.project.insuranceType")}
                            </p>

                            <div className="flex flex-row justify-between items-center">
                              <span className="BodyM font-medium text-[#424242] flex flex-row items-center gap-[8px]">
                                <GlobalIcon iconName="DisabledIcon" size="XS" />
                                {translate("edit.project.ipa")}
                              </span>
                              <div className="newDashboard-coverageToggle">
                                <span
                                  className="BodyM font-bold"
                                  style={{
                                    color:
                                      values.coverage === "basic"
                                        ? "#A0A0A0"
                                        : "var(--primary-color)",
                                    transition: "0.4s",
                                  }}
                                >
                                  {translate("dashboard.calcualtor.included")}
                                </span>
                                <Toggle
                                  initialValue={
                                    values.coverage === "basic" ? false : true
                                  }
                                  labels={[]}
                                  onValueChange={(value) => {
                                    const types = typeInsurance(translate);
                                    let newValue = "";
                                    if (value) {
                                      newValue =
                                        types.find((t) => t.value === "premium")
                                          ?.value ?? "basic";
                                    } else {
                                      newValue =
                                        types.find((t) => t.value === "basic")
                                          ?.value ?? "basic";
                                    }
                                    updateValues("coverage", newValue);
                                  }}
                                  disabled={
                                    traits.DISABILITY === false ||
                                    prices.ipaAvailable === false
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {isSeriousIllnesses ? (
                          <div className="flex flex-col gap-[12px]">
                            <div>
                              <p className="BodyL newDashboard-fieldLabel">
                                {translate("edit.project.insuranceType")}
                              </p>

                              <div className="flex flex-row justify-between items-center">
                                <span className="BodyM font-medium text-[#424242] flex flex-row items-center gap-[8px]">
                                  <GlobalIcon
                                    iconName="WiderUmbrellaIcon"
                                    size="XS"
                                    color="var(--primary-color)"
                                  />
                                  {translate("edit.project.eg")}
                                </span>
                                <div className="newDashboard-coverageToggle">
                                  <span
                                    className="BodyM font-bold"
                                    style={{ color: "var(--primary-color)" }}
                                  >
                                    {translate("dashboard.calcualtor.included")}
                                  </span>
                                  <GlobalIcon
                                    iconName="CircleCheckFilledIcon"
                                    color={"#018565"}
                                    size="XS"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="text-[#8E8E8E] BodyS flex flex-row items-center gap-[8px]">
                              <GlobalIcon
                                iconName="InfoIcon"
                                className="w-[18px] h-[18px]"
                                color="currentColor"
                              />
                              <p className="flex-1">
                                {translate("edit.project.egHelper")}
                              </p>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div className="editProjects-column">
                        <div className="editProjects-estimatedPrice">
                          <span className="BodyL editProjects-estimatedTitle">
                            {translate("edit.projects.estimatedPrice")}
                          </span>
                          <div className="flex flex-col gap-[4px] font-medium">
                            {prices.isPromoted && prices.beforePromoText ? (
                              <div
                                className="text-[#7B7B7B] BodyS"
                                dangerouslySetInnerHTML={{
                                  __html: prices.beforePromoText,
                                }}
                              />
                            ) : null}
                            <span className="H3 font-medium">
                              {Object.keys(prices).length > 0 &&
                                prices.monthlyPrice > 0
                                ? formatPriceWithoutCurrency(
                                  prices.isPromoted
                                    ? prices.promotedMonthlyPrice
                                    : prices.monthlyPrice
                                )
                                : translate("view.project.notAvailable")}
                              {prices.monthlyPrice > 0 && (
                                <span className="BodyS align-top">
                                  {translate("edit.projects.monthly")}
                                </span>
                              )}
                            </span>
                            {prices.isPromoted && prices.afterPromoText ? (
                              <div
                                className="text-[#7B7B7B] BodyS"
                                dangerouslySetInnerHTML={{
                                  __html: prices.afterPromoText,
                                }}
                              />
                            ) : null}
                          </div>
                          <div className="flex flex-col gap-[4px] font-medium">
                            {prices.isPromoted ? (
                              <div
                                className="text-[#7B7B7B] BodyS"
                                dangerouslySetInnerHTML={{
                                  __html: translate(
                                    "dashboard.calculator.withPromo"
                                  ),
                                }}
                              />
                            ) : null}
                            {prices.yearlyPrice > 0 && (
                              <span className="H3 font-medium">
                                {Object.keys(prices).length > 0
                                  ? formatPriceWithoutCurrency(
                                    prices.isPromoted
                                      ? prices.promotedYearlyPrice
                                      : prices.yearlyPrice
                                  )
                                  : translate("view.project.notAvailable")}
                                <span className="BodyS align-top">
                                  {translate("edit.projects.yearly")}
                                </span>
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex pt-2">
                      <ButtonDS
                        buttonType="tertiary"
                        ghost={true}
                        label="Documentos descargables"
                        size="32"
                        leftIcon="FileDownload"
                        className="editProjects-downloadableButton"
                        onClick={() => setDownloadableModal(true)}
                      />
                      {!prices.isPromoted ? <ButtonDS
                        buttonType="tertiary"
                        ghost={true}
                        label={translate("dashboard.calculator.addCoupon")}
                        size="32"
                        leftIcon="Tag2Icon"
                        className="editProjects-downloadableButton"
                        onClick={() => setCouponModal(true)}
                      /> : <ButtonDS
                        buttonType="tertiary"
                        ghost={true}
                        label={translate("dashboard.calculator.removePromo")}
                        size="32"
                        leftIcon="TrashIcon"
                        className="editProjects-downloadableButton"
                        onClick={() => handleRemoveCoupon("")}
                      />}
                    </div>
                  </>
                  ),
                },
                {
                  label: translate("edit.project.personalData"),
                  icon: "SimpleUserIcon",
                  panel: (
                    <div className="pt-[40px] editProjects">
                      <div className="editProjects-column">
                        <InputDS
                          value={values.referenceName}
                          label={translate("edit.projects.referenceName.label")}
                          icon="UserSearchIcon"
                          placeholder={translate(
                            "edit.projects.referenceName.placeholder"
                          )}
                          onChange={(e) =>
                            updateValues("referenceName", e.target.value)
                          }
                          onKeyDownCapture={onEnterBlur}
                        />
                        <InputDS
                          type="date"
                          value={values?.birthDate?.split(" ")[0]}
                          label={translate(
                            "edit.project.input.birthdate.label"
                          )}
                          icon="CalendarEventLineIcon"
                          onChange={(e) => {
                            if (modalData.birthDateEditable)
                              updateValues("birthDate", e.target.value);
                          }}
                          disabled={!modalData.birthDateEditable}
                          onKeyDownCapture={onEnterBlur}
                        />
                      </div>
                      <div className="editProjects-column">
                        <InputDS
                          value={values.phone}
                          label={translate("edit.projects.phone.label")}
                          icon="DeviceMobile"
                          placeholder={translate(
                            "edit.projects.phone.placeholder"
                          )}
                          onChange={(e) =>
                            updateValues("phone", e.target.value)
                          }
                          error={error.phone}
                          onKeyDownCapture={onEnterBlur}
                        />
                        <InputDS
                          value={getValidateEmail(values.email)}
                          label={translate("edit.projects.email.label")}
                          icon="MailIcon"
                          placeholder={translate(
                            "edit.projects.email.placeholder"
                          )}
                          onChange={(e) =>
                            updateValues("email", e.target.value)
                          }
                          error={error.email}
                          onKeyDownCapture={onEnterBlur}
                        />
                        {broker?.brokerageId === 1 ? (
                          isActiveRelatedEmail ? (
                            <InputDS
                              value={values.relatedEmail}
                              name="relatedEmail"
                              label={translate(
                                "edit.projects.relatedEmail.label"
                              )}
                              icon="MailForwardIcon"
                              placeholder={translate(
                                "edit.projects.relatedEmail.placeholder"
                              )}
                              onChange={(e) =>
                                updateValues("relatedEmail", e.target.value)
                              }
                              onKeyDownCapture={onEnterBlur}
                            />
                          ) : (
                            <ButtonDS
                              ghost
                              buttonType="tertiary"
                              label={translate(
                                "dashboard.calculator.addRelatedClient"
                              )}
                              onClick={() => setIsActiveRelatedEmail(true)}
                              size="32"
                              leftIcon="PlusIcon"
                              className="newDashboard-addRelatedClient"
                            />
                          )
                        ) : null}
                      </div>
                    </div>
                  ),
                },
              ]}
            />
          )
        }
        buttons={
          isSaved ? null : (
            <>
              <ButtonDS
                label={translate("edit.projects.save")}
                buttonType="secondary"
                leftIcon="CheckedIcon"
                onClick={saveData}
                disabled={isLoading}
              />
              <ButtonDS
                label={translate("edit.projects.saveAndRecover")}
                rightIcon="ArrowRightIcon"
                onClick={saveDataAndRecoverProject}
                disabled={
                  isLoading ||
                  prices.hasTelesubscription === true ||
                  prices?.monthlyPrice === 0
                }
              />
            </>
          )
        }
      />
      <DownloadableFilesModalDS
        open={downloadableModal}
        setOpen={setDownloadableModal}
        downloadablePdfs={downloadablePdfs}
        sendEmail={sendEmailDocument}
      />
      <CouponSelectorModal
        open={couponModal}
        onClose={() => setCouponModal(false)}
        validateCoupon={validateCoupon}
        fetchPromo={(coupon: string) => fetchPromo(coupon)}
        promoLoading={promoLoading}
        selector={true}
      />
    </>
  );
};

export default EditProjects;
