import { StatusFilter } from "./components/StatusFilter";
import {
  ListProjectsReference,
  ListProjectsV3,
  ListResponseV3,
} from "../../../api/request/Lists/Model/Response/ListResponse.model";
import {
  dateTimeToDate,
  validateEmailToTables,
} from "../../../utils/staticData";
import BrokerResponse from "../../../api/request/Broker/Model/Response/BrokerResponse.model";
import { formatPhone } from "./Utils/formatPhone";
import { renderstatus } from "./Utils/renderStatus";
import { columns, columnsAdmin, columnsIsGetlife } from "./constants/columns";
import {
  ButtonDS,
  CheckboxButton,
  GlobalIcon,
  InputDS,
  ModalDS,
  SelectDS,
} from "get-life-storybook-ts";
import { renderActions } from "./Utils/renderActions";
import { useNavigate } from "react-router";
import HttpLeadRepository from "../../../api/request/Lead/lead.service";
import { useContext, useEffect, useState } from "react";
import { ContextGetlife } from "../../../contexts/ContextGetlife";
import { markets } from "./constants/parseMarket";
import { ViewProjectData } from "../../../api/request/Lead/Model/EditProjectReponse.model";
import { getPrices } from "./Utils/getPrices";
import {
  pagination_buttons_left,
  pagination_buttons_right,
} from "./TableLife5Projects.controller";
import jwtDecode from "jwt-decode";
import FilterPopup from "./components/FilterPopup";
import OwnerFilterPopup from "./components/OwnerFilterPopup";

interface projectsInfoI {
  translate: (key: string) => string;
  projects: ListProjectsV3[];
  totalItems: number;
  filterByStage: (stage: string) => ListResponseV3[];
  filterByFilters: (filters: string) => ListResponseV3[];
  filterByBiometry: (filters: string) => ListProjectsV3[];
  pageSize: number;
  handlePageSize: (value: string) => void;
  handlePagination: (value: string) => void;
  indexPage: number;
  totalPages: number;
  direction: string;
  handleOrderingEffectDate: () => void;
}
interface table {
  projects: ListProjectsV3[];
  projectsInfo: projectsInfoI;
  broker: BrokerResponse | undefined;
  omniValue: string;
  setOmniValue: (e: string) => void;
  fetchProjects: ({
    stage,
    startD,
    endD,
    omniSearch,
    newPage,
    newPageSize,
    orderColumn,
  }: {
    stage?: string;
    startD?: string;
    endD?: string;
    omniSearch?: string;
    newPage?: number;
    newPageSize?: number;
    orderColumn?: string;
  }) => Promise<void>;
  recoverProject: (leadId: string, project: any, updateLead: boolean) => void;
  setModalData: (data: any) => void;
  setShowModal: (value: boolean) => void;
  viewModal: boolean;
  setViewModal: (value: boolean) => void;
  otpModal: boolean;
  setOtpModal: (value: boolean) => void;
  otpUrl: string;
  setOtpUrl: (value: string) => void;
  rangeProps: any;
  viewData: ViewProjectData;
  setViewData: (data: ViewProjectData) => void;
  getDownloableFiles: (leadId: string) => void;
  showNewIncident: (project: ListProjectsV3) => void;
  savedStage: string;
  setIdentificationModal: (v: boolean) => void;
  setIdentificationData: (data: any) => void;
  setSkipBiometryModal: (value: boolean) => void;
  setLeadActive: (lead: string) => string;
  exportCSV: () => Promise<void>;
  exportModal: boolean;
  setExportModal: (v: boolean) => void;
  exportDocsLoading: boolean;
  filterByOwners: (owners: string[]) => void;
  filterOwners: string[];
}

export const TableLife5Projects = ({
  projects,
  projectsInfo,
  broker,
  omniValue,
  setOmniValue,
  fetchProjects,
  setOtpModal,
  setOtpUrl,
  recoverProject,
  setModalData,
  setShowModal,
  setViewModal,
  rangeProps,
  viewData,
  setViewData,
  getDownloableFiles,
  showNewIncident,
  savedStage,
  setIdentificationModal,
  setIdentificationData,
  setSkipBiometryModal,
  setLeadActive,
  exportCSV,
  exportModal,
  setExportModal,
  exportDocsLoading,
  filterByOwners,
  filterOwners,
}: table) => {
  const [isArchiveMode, setIsArchiveMode] = useState(false);
  const [leadsToBeArchived, setLeadsToBeArchived] = useState<string[]>([]);
  const [archivedModal, setArchivedModal] = useState(false);
  const [isArchivedModal, setIsArchivedModal] = useState(false);
  const { token, DOMAIN, brokerId } = useContext(ContextGetlife);
  const leadRepository = new HttpLeadRepository(token);

  const {
    translate,
    totalItems,
    filterByStage,
    filterByFilters,
    filterByBiometry,
  } = projectsInfo;

  const navigate = useNavigate();

  const getColumns = () => {
    const user: any = jwtDecode(token);

    let col = columns;

    if (user?.roles && user?.roles.includes("ROLE_BROKER_SUPER_ADMIN")) {
      col = columnsAdmin;
    }
    if (broker?.brokerageId === 1) {
      col = columnsIsGetlife;
    }

    return col;
  };

  const renderTableHeader = () => {
    const columsToRender = getColumns();
    return columsToRender.map((column, key) => {
      return (
        <>
          <div
            className={`tableColumnDS min-w-0${
              column.borderLeft ? " border-l-2	border-[#ECECEC]" : ""
            }`}
            style={{
              width: column.width,
              flex: !column.width ? "1" : undefined,
            }}
          >
            {(() => {
              if (column.reference === "status") {
                return (
                  <FilterPopup
                    key={key}
                    label={translate(column.name)}
                    savedStage={savedStage}
                    filterByFilters={filterByFilters}
                    filterByStage={filterByStage}
                    filterByBiometry={filterByBiometry}
                  />
                );
              }
              if (column.reference === "proprietor") {
                return (
                  <OwnerFilterPopup
                    key={key}
                    label={translate(column.name)}
                    filterByOwners={filterByOwners}
                    filterOwners={filterOwners}
                  />
                );
              }
              if (column.reference === "effectDate") {
                return (
                  <div
                    key={key}
                    className="tableHeader flex BodyS font-bold uppercase whitespace-nowrap pointer"
                    onClick={() => projectsInfo.handleOrderingEffectDate()}
                  >
                    {translate(column.name)}
                    {projectsInfo.direction === "" ? (
                      <GlobalIcon
                        iconName="ArrowsSortDefault"
                        size="XS"
                        color="#ECECEC"
                        className="ml-auto rounded-[4px]"
                      />
                    ) : projectsInfo.direction === "DESC" ? (
                      <GlobalIcon
                        iconName="ArrowsSortDesc"
                        size="XS"
                        color="#ECECEC"
                        className="ml-auto rounded-[4px]"
                      />
                    ) : (
                      <GlobalIcon
                        iconName="ArrowsSortAsc"
                        size="XS"
                        color="#ECECEC"
                        className="ml-auto rounded-[4px]"
                      />
                    )}
                  </div>
                );
              }
              return (
                <div
                  key={key}
                  className="tableHeader flex BodyS font-bold uppercase whitespace-nowrap"
                >
                  <span className="containerText">
                    {translate(column.name)}
                  </span>
                </div>
              );
            })()}
            {projects.map((project, idx) => {
              return (
                <div
                  className={`tableBodyContainerDS w-full flex ${
                    idx % 2 === 0 ? "rowOdd" : "rowEven"
                  }`}
                  key={`column_${idx}`}
                  data-tobearchived={
                    leadsToBeArchived.includes(project.leadId) ? true : null
                  }
                >
                  {parseData(
                    project,
                    column.reference,
                    projects.length - 1,
                    idx
                  )}
                </div>
              );
            })}
          </div>
        </>
      );
    });
  };

  const editLead = async (project: any) => {
    const data = await leadRepository.getViewProject(project.leadId);
    setModalData({
      ...data.personalData,
      ...data.product,
      leadId: project.leadId,
      status: project.status,
    });
    setShowModal(true);
  };

  const recoverLead = (leadId: string, project: any) => {
    recoverProject(leadId, project, false);
  };

  const viewLead = async (leadId: string, project: any) => {
    const data = await leadRepository.getPreviewProject(leadId);
    setViewData({ ...data, status: project.status, leadId: leadId });
    setViewModal(true);
  };

  const getOtp = async (leadId: string) => {
    const data = await leadRepository.getOtp(leadId);
    setOtpUrl(data.otpUrl);
    setOtpModal(true);
  };

  const toggleIdentification = (project: any) => {
    setIdentificationData({
      leadId: project.leadId,
    });
    setIdentificationModal(true);
  };

  const skipBiometry = (leadId: string) => {
    setLeadActive(leadId);
    setSkipBiometryModal(true);
  };

  const buildTotals = () => {
    return `Pág. ${projectsInfo.indexPage} - Pág. ${projectsInfo.totalPages}`;
  };

  const parseData = (
    project: ListProjectsV3,
    ref: ListProjectsReference,
    length: number,
    position: number
  ) => {
    const isBottom = length - 3 <= position;

    if (ref === "phone") {
      return formatPhone(project.phone);
    }

    if (ref === "email") {
      return (
        <div className="containerText flex flex-row gap-[16px]">
          {isArchiveMode ? (
            <CheckboxButton
              label={""}
              value={leadsToBeArchived.includes(project.leadId)}
              checked={leadsToBeArchived.includes(project.leadId)}
              onChange={() => {
                if (project.isDeleteable)
                  toggleLeadToBeArchived(project.leadId);
              }}
              variant={"SimpleCheck"}
              disabled={!project.isDeleteable}
            />
          ) : null}
          <span className="min-w-0 flex-1 containerText">
            {validateEmailToTables(translate, project.email)}
          </span>
        </div>
      );
    }

    if (ref === "effectDate") {
      return dateTimeToDate(project.effectDate);
    }

    if (ref === "annualPrice") {
      return (
        <div className="whitespace-nowrap flex flex-row items-center gap-[8px]">
          {project.annualPrice !== undefined ? (
            getPrices(translate, project.annualPrice as any)
          ) : (
            <p>{translate("table.capital.notAvailable")}</p>
          )}
        </div>
      );
    }

    if (ref === "actions") {
      return renderActions(
        translate,
        project,
        recoverLead,
        editLead,
        getOtp,
        viewLead,
        getDownloableFiles,
        showNewIncident,
        isBottom,
        toggleIdentification,
        skipBiometry,
        broker,
        DOMAIN
      );
    }

    if (ref === "status") {
      return renderstatus(translate, project, isBottom);
    }

    if (ref === "market") {
      return markets[project.market];
    }

    if (ref === "proprietor") {
      return <span className="containerText">{project.owner}</span>;
    }

    if (ref === "nameList") {
      return <span className="containerText">{project[ref]}</span>;
    }
  };

  const optionsPage = [
    {
      label: "10",
      value: "10",
    },
    {
      label: "15",
      value: "15",
    },
    {
      label: "20",
      value: "20",
    },
  ];

  const newProject = () => {
    navigate("/dashboard/calculator");
  };

  const toggleLeadToBeArchived = (leadId: string) => {
    let _leadsToBeArchived = [...leadsToBeArchived];
    if (_leadsToBeArchived.includes(leadId)) {
      _leadsToBeArchived = _leadsToBeArchived.filter(
        (_leadId) => _leadId !== leadId
      );
    } else {
      _leadsToBeArchived.push(leadId);
    }
    setLeadsToBeArchived(_leadsToBeArchived);
  };

  const cancelArchive = () => {
    setIsArchiveMode(false);
    setLeadsToBeArchived([]);
  };

  const archiveSelectedProjects = () => {
    // @to-do call back and send selected projects to archive them
    leadRepository.deleteLeads(brokerId, leadsToBeArchived).then((response) => {
      cancelArchive();
      fetchProjects({});
      setIsArchivedModal(true);
    });
  };

  useEffect(() => {
    const tableHeaderContainers = document.querySelector(
      ".tableHeaderContainerDS"
    );
    if (tableHeaderContainers) {
      const tableColumnContainers: NodeListOf<HTMLElement> =
        tableHeaderContainers.querySelectorAll(".tableColumnDS");
      tableColumnContainers.forEach((container) => {
        const children = container.children;
        Array.from(children).forEach((child) => {
          (child as HTMLElement).style.borderTop = "";
          (child as HTMLElement).style.borderBottom = "";
          if (child.getAttribute("data-tobearchived")) {
            if (
              !(
                child.previousElementSibling &&
                child.previousElementSibling.getAttribute("data-tobearchived")
              )
            ) {
              (child as HTMLElement).style.borderTop = "2px solid #c2c1f7";
            }

            if (
              !(
                child.nextElementSibling &&
                child.nextElementSibling.getAttribute("data-tobearchived")
              )
            ) {
              (child as HTMLElement).style.borderBottom = "2px solid #c2c1f7";
            }
          }
        });
      });
    }
  }, [leadsToBeArchived, projects]);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    if (archivedModal === false) {
      timer = setTimeout(() => {
        setIsArchivedModal(false);
      }, 200);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [archivedModal]);

  return (
    <>
      <ModalDS
        open={archivedModal}
        title={
          isArchivedModal
            ? translate("projects.archived.modal.title")
            : translate("projects.archive.modal.title")
        }
        icon={isArchivedModal ? "Tag2Icon" : "InfoIcon"}
        content={
          isArchivedModal ? (
            <div>
              <span className="p-[8px] bg-[#ECECFE] block rounded-full w-fit mb-[16px]">
                <GlobalIcon
                  iconName="CheckedIcon"
                  color="var(--primary-color)"
                  size="XS"
                />
              </span>
              <p
                className="BodyM text-[#424242]"
                dangerouslySetInnerHTML={{
                  __html: translate("projects.archived.modal.body"),
                }}
              />
            </div>
          ) : (
            <p
              className="BodyM text-[#424242]"
              dangerouslySetInnerHTML={{
                __html: translate("projects.archive.modal.body"),
              }}
            />
          )
        }
        buttons={
          isArchivedModal ? null : (
            <>
              <ButtonDS
                label={translate("projects.archive.modal.cancel")}
                buttonType="secondary"
                onClick={() => setArchivedModal(false)}
              />
              <ButtonDS
                label={translate("projects.archive.modal.archive")}
                onClick={archiveSelectedProjects}
              />
            </>
          )
        }
        onClose={() => {
          setArchivedModal(false);
        }}
        autoClose={isArchivedModal}
      />
      <div className="w-full">
        <div className="w-full gap-4">
          <div className="pb-6">
            <p className="H3 font-bold text-dark-gray-100">
              {translate("projects.header.title")}
            </p>
            <p className="BodyM text-dark-gray-100">{`${totalItems} ${translate(
              "projects.header.subtitle"
            )}`}</p>
          </div>

          <div className=" flex flex-col gap-10">
            <StatusFilter
              filterByStage={filterByStage}
              savedStage={savedStage}
            />
            <div className="flex justify-between pb-6">
              <div className="w-[447px]">
                <InputDS
                  value={omniValue}
                  name="omniSearch"
                  label={translate("projects.search.label")}
                  icon="SearchIcon"
                  placeholder={translate("projects.search.placeholder")}
                  onChange={(e) => setOmniValue(e.target.value)}
                  clearInputCallback={() =>
                    fetchProjects({ omniSearch: "", newPage: 1 })
                  }
                  type="search"
                  className="w-[447px]"
                />
              </div>
              <div className="flex gap-4 items-end">
                {isArchiveMode ? (
                  <>
                    <ButtonDS
                      label={translate("projects.archive.cancel")}
                      buttonType="tertiary"
                      ghost={true}
                      onClick={cancelArchive}
                    />
                    <ButtonDS
                      label={translate("projects.archive.projects")}
                      buttonType="primary"
                      leftIcon="ArchiveIcon"
                      onClick={() => setArchivedModal(true)}
                      disabled={leadsToBeArchived.length === 0}
                    />
                  </>
                ) : (
                  <ButtonDS
                    label={translate("projects.archive.projects")}
                    buttonType="secondary"
                    leftIcon="ArchiveIcon"
                    onClick={() => setIsArchiveMode(true)}
                  />
                )}
                {broker?.brokerageId !== 1 ? (
                  <ButtonDS
                    label={translate("projects.export.csv")}
                    leftIcon="DownloadIcon"
                    buttonType="tertiary"
                    onClick={() => exportCSV()}
                    disabled={exportDocsLoading || isArchiveMode}
                  />
                ) : null}
                <ButtonDS
                  onClick={() => newProject()}
                  label={translate("projects.noData.buttonText")}
                  buttonType={"primary"}
                  className="h-fit w-fit"
                  leftIcon="PlusIcon"
                  disabled={isArchiveMode}
                />
              </div>
            </div>
          </div>

          <div className="tableHeaderContainerDS">{renderTableHeader()}</div>
          <div className="tableFooterContainer w-full">
            <div className="custom-table__footer">
              <div className="custom-table__rowsper"></div>
              <div className="custom-table__pagination">
                <div className="custom-table__pagination__totals BodyM font-medium text-[#555]">
                  {buildTotals()}
                </div>
                <div className="custom-table__pagination__buttons">
                  {pagination_buttons_left.map((element, index) => (
                    <button
                      key={index}
                      onClick={() => {
                        projectsInfo.handlePagination(element.value);
                      }}
                    >
                      <GlobalIcon
                        iconName={element.icon}
                        size="XS"
                        color="currentColor"
                      />
                    </button>
                  ))}
                </div>
                <div className="custom-table__pagination__buttons">
                  {pagination_buttons_right.map((element, index) => (
                    <button
                      key={index}
                      onClick={() => {
                        projectsInfo.handlePagination(element.value);
                      }}
                    >
                      <GlobalIcon
                        iconName={element.icon}
                        size="XS"
                        color="currentColor"
                      />
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="containerPage">
            <SelectDS
              label={translate("tables.footer.filter")}
              options={optionsPage}
              value={projectsInfo.pageSize}
              onChange={(e) => projectsInfo.handlePageSize(e.target.value)}
            />
          </div>
        </div>
      </div>
      <ModalDS
        open={exportModal}
        title={translate("projects.export.csv")}
        icon="DownloadIcon"
        content={
          <div className="BodyM text-[#424242]">
            <div className="p-[8px] bg-[#ECECFE] rounded-full w-fit mb-[16px]">
              <GlobalIcon
                iconName="CheckedIcon"
                size="XS"
                color="var(--primary-color)"
              />
            </div>
            {translate("projects.csv.exported")}
          </div>
        }
        onClose={() => setExportModal(false)}
        autoClose={exportModal}
      />
    </>
  );
};
