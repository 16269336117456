// Vendors
import { useContext, useEffect, useState } from "react";
import moment from "moment";
// Utils
import numberFormat from "../../utils/numberFormat";
// Styles
import "./css/earningsMain.scss";
import { ContextGetlife } from "../../contexts/ContextGetlife";
// API
import HttpBrokerRepository from "../../api/request/Broker/broker.service";
import HttpListRepository from "../../api/request/Lists/list.service";
import { I18nContext } from "../../contexts/i18n.context";
import CardsBroker from "../CardsBroker/CardsBroker";
import { ButtonDS, Chip, GlobalIcon } from "get-life-storybook-ts";
import Spinner from "get-life-storybook-ts/lib/components/Icons/Spinner";

export default function Earnings() {
  // TODO: Refactor incoming.
  const { brokerId, token } = useContext(ContextGetlife);
  const brokerRepository = new HttpBrokerRepository(brokerId, token);
  const listRepository = new HttpListRepository(token);
  //Download
  const [docList, setDocList] = useState([]);
  // Range
  const [totalAmount, setTotalAmount] = useState(false);
  const [totalCount, setTotalCount] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [totalCommissions, setTotalCommissions] = useState(0);

  const {
    state: { translate },
  } = useContext(I18nContext);

  useEffect(() => {
    const dateFormat = "YYYY-MM-DD";
    const fillData = () => {
      setLoading(true);
      brokerRepository.getEarnings("2020-01-01", endDate).then((data) => {
        setTotalCount(data.policiesCount);
        setTotalAmount(data.totalAmount);
        setLoading(false);
      });

      listRepository.getInvoiceList().then((data) => {
        setLoadingList(true);
        try {
          setDocList(data);
          let total = 0;
          data.forEach(function (commission) {
            total += commission.amount;
          });
          setTotalCommissions(total);
          setLoadingList(false);
        } catch (error) {
          setLoadingList(false);
        }
      });
    };

    const endDate = moment().local().format(dateFormat);
    fillData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brokerId, token]);

  const CardInfo = ({ icon, number, text }) => {
    return (
      <div className="w-[270px] flex flex-col items-center gap-3 px-5 py-6 relative rounded-lg">
        <div className="relative w-10 h-10 bg-[#F5F7FA] rounded-[100px]">
          <GlobalIcon iconName={icon} size="S" color="#423ef4" />
        </div>
        <div className="H4 font-bold text-[#424242]">
          {number}
        </div>
        <div className="BodyM font-regular text-[#686868]">
          {text}
        </div>
      </div>
    )
  }

  return (

    <>
      <div className="newDashboard-grid">
        <div className="flex flex-col">
          <span className="H3 font-bold text-[#424242]">{translate("earnings.title")}</span>
          <span className="BodyM font-light text-[#424242]">{translate("earnings.subtitle")}</span>
        </div>

      </div>
      <div className="newDashboard-grid">
        <div className="flex flex-col gap-10">
          <div className="newDashboard-calculator">
            <p className="BodyL font-bold flex flex-row gap-[16px] text-[#424242]">
              <GlobalIcon iconName="CashIcon" size="XS" color="currentColor" />
              {translate("earnings.card.title")}
            </p>
            <div className="flex flex-row gap-10 justify-center items-center">
              {(docList.length !== 0 && totalCommissions !== 0) && <CardInfo
                icon={"CurrencyEuroIcon"}
                number={loadingList ? <Spinner /> : numberFormat(totalCommissions, "price")}
                text={translate("earnings.card.commissions")}
              />}
              <CardInfo
                icon={"FileEuroIcon"}
                number={loading ? <Spinner /> : numberFormat(totalAmount, "price")}
                text={translate("earnings.card.primas")}
              />
              <CardInfo
                icon={"FileCheckIcon"}
                number={loading ? <Spinner /> : totalCount}
                text={translate("earnings.card.policies")}
              />
            </div>
          </div>
          {docList.length !== 0 && (
            <div className="newDashboard-calculator gap-10">
              <div className="flex">
                <p className="BodyL font-bold flex flex-row gap-[16px] text-[#424242]">
                  <GlobalIcon iconName="DownloadIcon" size="XS" color="currentColor" />
                  {translate("earnings.documents.title")}
                </p>
              </div>
              <div>
                {
                  docList.map((doc) => {
                    return (
                      <div className={`flex flex-row px-4 py-6 border-[1px] ${doc.invoiceDocumentId === docList[0].invoiceDocumentId && "rounded-t-lg"} ${doc.invoiceDocumentId === docList[docList.length -1 ].invoiceDocumentId && "rounded-b-lg"} border-[#ECECEC] justify-between`}>
                        <div className="flex flex-row gap-4">
                          <GlobalIcon iconName="ContractValidatedIcon" size="M" color="#423ef4" />
                          <div className="flex flex-col">
                            <span className="BodyL font-bold text-[#424242]">{doc.name.substring(0, doc.name.length - 4)}</span>
                            <Chip
                              text={`${translate("earning.document.chip")} ${numberFormat(doc.amount, "price")}`}
                              type="default"
                              icon={"CashIcon"}
                            />
                          </div>
                        </div>
                        <div>
                          <ButtonDS 
                            label={`${translate("tables.buttons.descargar")} ${doc.name.split(".")[1].toUpperCase()}`}
                            leftIcon="FileDownload"
                            size="40"
                            href={`${process.env.REACT_APP_CUSTOMER_APP_URL}api/public/invoices/download/${doc.invoiceDocumentId}`}
                            target="_blank"
                          />
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          )}
        </div>

        <CardsBroker />
      </div>
    </>
  );
}
