/* eslint-disable react-hooks/exhaustive-deps */
// Vendors
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
// Api
import HttpBrokerRepository from "../../../api/request/Broker/broker.service";
// Context
import { useLoginOtpContext } from "../context/login-otp.context";
// Handlers
import LoginOtpHandlers from "../handlers/login-otp.container.handlers";
import { useContext, useEffect } from "react";
import { ContextGetlife } from "../../../contexts/ContextGetlife";
import { I18nContext } from "../../../contexts/i18n.context";

const LoginOtpController = (setLoading) => {
  const navigate = useNavigate();
  const params = useParams();
  const { setShowNotification, setToken, setBrokerId } =
    useContext(ContextGetlife);
  const {
    state: { translate },
  } = useContext(I18nContext);
  const brokerRepository = new HttpBrokerRepository();
  const { contactValue, inputType, requested, setInputType, setRequested } =
    useLoginOtpContext();
  const { handleClickChooseBetter, handleClickNoAccount, handleClickNoCode } =
    LoginOtpHandlers(
      brokerRepository,
      contactValue,
      navigate,
      inputType,
      setInputType,
      setRequested,
      setLoading
    );

  const fetch = async () => {
    try {
      const response = await brokerRepository.loginByOtp(params.OTP);
      setBrokerId(response.id);
      setToken(response.token);
    } catch (e) {
      navigate("/login");
      setShowNotification({
        message: translate(`errors.login.${e.message}`),
        status: true,
        type: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    }
  };

  useEffect(() => {
    (async () => {
      if (params.OTP) {
        await fetch();
      }
    })();
  }, []);
  return {
    handleClickChooseBetter,
    handleClickNoAccount,
    handleClickNoCode,
    inputType,
    requested,
    translate,
  };
};

export default LoginOtpController;
