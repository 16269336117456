import LoginOtpController from "./controllers/login-otp.container.controllers";

import { withLoginOtpProvider } from "./context/login-otp.context";
import { useContext } from "react";
import { ContextGetlife } from "../../contexts/ContextGetlife";
import { ButtonDS, InputDS } from "get-life-storybook-ts";
import LoginOtpRequestController from "./views/controllers/login-otp-request.controller";
import InputOtp from "../components/inputOtp/input-otp.component";
import LoginOtpSubmitViewController from "./views/controllers/login-otp-submit.controller";

const LoginOtp = () => {
  const { brokerId, setBroker, setBrokerId, setStatus, setToken, token } =
    useContext(ContextGetlife);
  const {
    handleClickChooseBetter,
    handleClickNoAccount,
    handleClickNoCode,
    inputType,
    requested,
    translate,
  } = LoginOtpController(brokerId, setBroker, token, setStatus);

  const {
    contactValue,
    errorMessage,
    handleClickSubmit,
    handleKeyDown,
    setContactValue,
  } = LoginOtpRequestController();

  const {
    errorOtp,
    errorMessageOtp,
    handleClickSubmitOtp,
    setErrorOtp,
    setValue,
    value,
  } = LoginOtpSubmitViewController(setBroker, setBrokerId, setToken, setStatus);

  return (
    <>
      <div className="h-full min-h-fit bg-[#F5F7FA] lg:!p-[80px] p-[24px] flex justify-center items-center">
        <div className="flex flex-col gap-[80px] bg-white lg:!py-[56px] lg:!px-[64px] py-[24px] px-[24px] rounded-[12px] w-[528px] shadow-[0px_1px_3px_0px_rgba(0,_0,_0,_0.10),_0px_2px_1px_0px_rgba(0,_0,_0,_0.06),_0px_1px_1px_0px_rgba(0,_0,_0,_0.07)]">
          {requested ? (
            <>
              <img
                src="https://storage.googleapis.com/getlife-bucket-1/public/image/life5/Life_5_Logo_BG.svg"
                width={95}
                className="mx-auto"
                alt=""
              />
              <div className="flex flex-col gap-[8px]">
                <span className="text-[#424242] bodyM">
                  {translate("login.code")}
                </span>
                <InputOtp
                  value={value}
                  setValue={setValue}
                  error={errorOtp}
                  setError={setErrorOtp}
                />
                {errorMessageOtp !== "" ? (
                  <span className="BodyS text-[#E84B4B]">
                    {translate(errorMessageOtp)}
                  </span>
                ) : null}
                <ButtonDS
                  label={translate("login.enter")}
                  size="40"
                  className="mt-[16px]"
                  disabled={errorOtp ? true : false}
                  onClick={handleClickSubmitOtp}
                />
                <span className="bodyM text-[var(--dark-primary-color)] text-center">
                  {translate("login.page.unreceived.code")}{" "}
                  <button
                    className="font-bold underline"
                    onClick={handleClickNoCode}
                  >
                    {translate("login.page.resend.code")}
                  </button>
                </span>
                <span className="mt-[16px] text-[#424242] BodyM text-center py-[16px]">
                  {inputType === "tel"
                    ? translate("login.page.login.email")
                    : translate("login.page.login.tel")}{" "}
                  <button
                    className="font-bold underline"
                    onClick={() => {
                      if (inputType === "email") {
                        handleClickChooseBetter("tel");
                      }
                      if (inputType === "tel") {
                        handleClickChooseBetter("email");
                      }
                    }}
                  >
                    {translate("login.page.click.here")}
                  </button>
                </span>
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-col gap-[32px]">
                <img
                  src="https://storage.googleapis.com/getlife-bucket-1/public/image/life5/Life_5_Logo_BG.svg"
                  width={95}
                  className="mx-auto"
                  alt=""
                />
                <div className="flex flex-col gap-[8px]">
                  <h2 className="font-DM H3 font-bold text-[#424242] text-center">
                    {translate("login.page.welcome")}
                  </h2>
                  <h3 className="BodyM font-medium text-[#272727] text-center">
                    {translate("login.page.welcome.body")}
                  </h3>
                </div>
              </div>
              <div className="flex flex-col gap-[32px]">
                {inputType === "email" ? (
                  <InputDS
                    type="email"
                    label={translate("login.label.email")}
                    placeholder={translate("login.placeholder.email")}
                    icon="MailIcon"
                    onChange={(e) => setContactValue(e.target.value)}
                    error={translate(errorMessage)}
                    value={contactValue}
                    onKeyDown={handleKeyDown}
                  />
                ) : null}
                {inputType === "tel" ? (
                  <InputDS
                    type="tel"
                    label={translate("login.label.mobile")}
                    placeholder={translate("login.placeholder.mobile")}
                    icon="PhoneIcon"
                    onChange={(e) => setContactValue(e.target.value)}
                    error={translate(errorMessage)}
                    value={contactValue}
                    onKeyDown={handleKeyDown}
                  />
                ) : null}
                <ButtonDS
                  buttonType="primary"
                  label={translate("login.send_code")}
                  size="40"
                  onClick={handleClickSubmit}
                  disabled={!contactValue}
                />
                <p className="text-[#272727] BodyM font-medium">
                  {inputType === "tel"
                    ? translate("login.page.login.email")
                    : translate("login.page.login.tel")}{" "}
                  <button
                    className="underline font-bold cursor-pointer"
                    onClick={() => handleClickChooseBetter(inputType)}
                  >
                    {translate("login.page.click.here")}
                  </button>
                  <br />
                  <span className="mt-[8px] inline-block">
                    {inputType === "tel"
                      ? translate("login.phone.paragraph")
                      : translate("login.email.paragraph")}
                  </span>
                </p>
              </div>
              <div className="text-[#424242] BodyM font-medium">
                {translate("login.no_account_text")}{" "}
                <button
                  className="text-[var(--dark-primary-color)] underline uppercase font-bold"
                  onClick={handleClickNoAccount}
                >
                  {translate("login.register")}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default withLoginOtpProvider(LoginOtp);
