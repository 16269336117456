import { ListProjectsReference } from "../../../../api/request/Lists/Model/Response/ListResponse.model";

export interface columnsI {
  name: string;
  reference: ListProjectsReference;
  width?: string;
  borderLeft?: boolean;
}

export const columns: columnsI[] = [
  {
    name: "tables.headers.email",
    reference: "email",
  },
  {
    name: "tables.headers.name",
    reference: "nameList",
  },
  {
    name: "tables.headers.phone",
    reference: "phone",
    width: "fit-content",
  },
  {
    name: "tables.headers.status",
    reference: "status",
    borderLeft: true,
    width: "fit-content",
  },
  {
    name: "tables.headers.annualPrice",
    reference: "annualPrice",
    width: "fit-content",
  },
  {
    name: "tables.headers.effectDate",
    reference: "effectDate",
    width: "fit-content",
  },
  {
    name: "tables.headers.actions",
    reference: "actions",
    width: "fit-content",
    borderLeft: true,
  },
];

export const columnsAdmin: columnsI[] = [
  {
    name: "tables.headers.email",
    reference: "email",
  },
  {
    name: "tables.headers.name",
    reference: "nameList",
  },
  {
    name: "tables.headers.phone",
    reference: "phone",
    width: "fit-content",
  },
  {
    name: "tables.headers.status",
    reference: "status",
    borderLeft: true,
    width: "fit-content",
  },
  {
    name: "tables.headers.annualPrice",
    reference: "annualPrice",
    width: "fit-content",
  },
  {
    name: "tables.headers.effectDate",
    reference: "effectDate",
    width: "fit-content",
  },
  {
    name: "tables.headers.owner",
    reference: "proprietor",
  },
  {
    name: "tables.headers.actions",
    reference: "actions",
    width: "fit-content",
    borderLeft: true,
  },
];

export const columnsIsGetlife: columnsI[] = [
  {
    name: "tables.headers.email",
    reference: "email",
  },
  {
    name: "tables.headers.name",
    reference: "nameList",
  },
  {
    name: "tables.headers.phone",
    reference: "phone",
    width: "fit-content",
  },
  {
    name: "tables.headers.status",
    reference: "status",
    borderLeft: true,
    width: "fit-content",
  },
  {
    name: "tables.headers.annualPrice",
    reference: "annualPrice",
    width: "fit-content",
  },
  {
    name: "tables.headers.effectDate",
    reference: "effectDate",
    width: "fit-content",
  },
  {
    name: "tables.headers.market",
    reference: "market",
    width: "fit-content",
  },
  {
    name: "tables.headers.actions",
    reference: "actions",
    width: "fit-content",
    borderLeft: true,
  },
];
