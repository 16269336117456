import { ButtonDS, CheckboxButton, InputDS } from "get-life-storybook-ts";

export default function RegisterMain(props) {
  const {
    acceptTerms,
    authorizeNumber,
    brokerageName,
    dni,
    email,
    handleAcceptTerms,
    handleAuthorizeNumber,
    handleBrokerageName,
    handleDni,
    handleEmail,
    handleHaveAuthorization,
    handleHaveRcSecure,
    handleLastName,
    handleName,
    handlePersonalPhone,
    handleSubmit,
    haveAuthorization,
    haveRcSecure,
    lastName,
    name,
    personalPhone,
    translate,
  } = props;

  const allStates = [
    acceptTerms,
    authorizeNumber,
    brokerageName,
    dni,
    email,
    haveAuthorization,
    haveRcSecure,
    lastName,
    name,
    personalPhone,
  ];

  const isButtonDisabled = () => {
    let filtered = false;
    for (let i = 0; i <= allStates.length; i++) {
      if (allStates[i] === false || allStates[i] === "") {
        filtered = true;
      }
    }
    return filtered;
  };

  return (
    <main className="flex justify-center h-full min-h-fit bg-[#F5F7FA] lg:!p-[80px] p-[24px]">
      <div className="lg:max-w-[1120px] w-full grid lg:grid-cols-[326px,_1fr] lg:gap-[64px] gap-[32px] h-fit justify-center">
        <div className="flex flex-col gap-[32px] max-w-[496px]">
          <img
            src="https://storage.googleapis.com/getlife-bucket-1/public/image/life5/Life_5_Logo_BG.svg"
            width={95}
            alt=""
          />
          <div className="flex flex-col gap-[8px]">
            <h2 className="H3 font-bold text-[#424242] font-DM">
              {translate("register.title")}
            </h2>
            <p className="BodyM text-[#272727] font-medium">
              {translate("register.subtitle")}
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-[16px] bg-white py-[40px] px-[48px] rounded-[12px] max-w-[496px] shadow-[0px_1px_3px_0px_rgba(0,_0,_0,_0.10),_0px_2px_1px_0px_rgba(0,_0,_0,_0.06),_0px_1px_1px_0px_rgba(0,_0,_0,_0.07)] h-fit">
          <InputDS
            label={translate("register.label.brokerageName")}
            onChange={(e) => handleBrokerageName(e.target.value)}
            value={brokerageName ? brokerageName : ""}
          />
          <div className="grid lg:grid-cols-2 gap-[16px]">
            <InputDS
              label={translate("register.label.name")}
              onChange={(e) => handleName(e.target.value)}
              value={name ? name : ""}
            />
            <InputDS
              label={translate("register.label.lastName")}
              onChange={(e) => handleLastName(e.target.value)}
              value={lastName ? lastName : ""}
            />
          </div>
          <InputDS
            type="tel"
            label={translate("register.label.phone")}
            onChange={(e) => handlePersonalPhone(e.target.value)}
            value={personalPhone ? personalPhone : ""}
            helper={translate("register.page.tel.helper")}
          />
          <InputDS
            type="email"
            label={translate("register.label.email")}
            onChange={(e) => handleEmail(e.target.value)}
            value={email ? email : ""}
            helper={translate("register.page.email.helper")}
          />
          <div className="mt-[16px]">
            <InputDS
              label={translate("register.label.authorizeNumber")}
              onChange={(e) => handleAuthorizeNumber(e.target.value)}
              value={authorizeNumber ? authorizeNumber : ""}
            />
          </div>
          <InputDS
            label={translate("register.label.idNumbe")}
            onChange={(e) => handleDni(e.target.value)}
            value={dni ? dni : ""}
          />
          <div className="mt-[16px] flex flex-col gap-[16px]">
            <div className="flex flex-row gap-[8px]">
              <CheckboxButton
                onChange={(e) => handleHaveRcSecure(e.target.checked)}
                checked={haveRcSecure}
                variant={"SimpleCheck"}
              />
              <span className="text-[#424242] BodyM">
                {translate("register.label.insurance")}
              </span>
            </div>
            <div className="flex flex-row gap-[8px]">
              <CheckboxButton
                onChange={(e) => handleHaveAuthorization(e.target.checked)}
                checked={haveAuthorization}
                variant={"SimpleCheck"}
              />
              <span className="text-[#424242] BodyM">
                {translate("register.label.authorization")}
              </span>
            </div>
            <div className="flex flex-row gap-[8px]">
              <CheckboxButton
                onChange={(e) => handleAcceptTerms(e.target.checked)}
                checked={acceptTerms}
                variant={"SimpleCheck"}
              />
              <div className="text-[#424242] BodyM">
                {translate("register.accept.text1")}{" "}
                <a
                  href={translate("register.accept.link1")}
                  target="_blank"
                  rel="noreferrer"
                  className="text-[#424242] font-normal"
                >
                  {translate("register.accept.text2")}
                </a>
                {translate("register.accept.text3")}{" "}
                <a
                  href={translate("register.accept.link2")}
                  target="_blank"
                  rel="noreferrer"
                  className="text-[#424242] font-normal"
                >
                  {translate("register.accept.text4")}
                </a>
              </div>
            </div>
            <ButtonDS
              label={translate("register.button")}
              disabled={isButtonDisabled()}
              onClick={handleSubmit}
              className="my-[24px]"
            />
          </div>
        </div>
      </div>
    </main>
  );
}
